<template>
  <div>
    <v-card class="pa-2">
      <v-card-title class="primary--text">Update your Info</v-card-title>
      <div class="about-avatar ml-2">
        <div class="avatar-container">
          <v-avatar size="200" :color="user.avatar ? 'white' : 'primary'" class="text-bold white--text heading">
            <v-img v-if="user.avatar" :src="user.avatar"></v-img>
            <span v-else>{{ user.full_name.charAt(0) }}</span>
          </v-avatar>
          <v-btn @click="aboutPhotosModal = true" :loading="updateAvatarButtonLoad"
          small class="avatar-button" fab
          color="grey lighten-2" absolute bottom
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
          <!-- image Dialog -->
        <v-dialog v-model="aboutPhotosModal" width="50%">
          <v-card class="pa-5 text-center">
            <croppa
            v-model="croppa"
            :width="150"
            :height="150"
            :quality="2"
            prevent-white-space
            :placeholder-font-size="18"
            @init="onInit"
            ></croppa>
            <div class="btns">
              <v-btn icon color="primary darken-2" @click="croppa.zoomIn()"><v-icon>mdi-magnify-plus</v-icon></v-btn>
              <v-btn icon color="primary darken-2" @click="croppa.zoomOut()"><v-icon>mdi-magnify-minus</v-icon></v-btn>
            </div>
            <v-divider></v-divider>
            <v-btn class="d-block mx-auto" text color="primary" @click="changeAvatarPhoto"><v-icon>mdi-download</v-icon> Save picture</v-btn>
          </v-card>
        </v-dialog>
          <!-- <v-dialog
            v-model="aboutPhotosModal"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn :loading="updateAvatarButtonLoad" v-bind="attrs" v-on="on" small class="avatar-button" fab
                     color="grey lighten-2" absolute bottom>
                <v-icon>mdi-camera</v-icon>
              </v-btn>
            </template>

            <v-card class="pa-5">
              <v-form ref="photoFormAbout" v-model="photoModalAboutValid">
                <v-card-title class="primary--text">
                  Change Avatar
                </v-card-title>
                <v-file-input outlined v-model="avatarPhotoAbout" accept="image/*"
                              label="Choose photo" :rules="[rules.required]"></v-file-input>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="changeAvatarPhoto() ? aboutPhotosModal = false : aboutPhotosModal = true"
                  >
                    Upload
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="aboutPhotosModal = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog> -->
        </div>
        <p style="font-size: 20px" class="text-center mt-10 primary--text"><strong>Edit Your avatar</strong></p>
      </div>
      <v-divider></v-divider>
      <v-simple-table class="table">
        <tbody>
        <tr>
          <th class="font-weight-bold" style="font-size: 14px">About Me</th>
          <td v-if="aboutMe !== null && aboutMe !== '' && editingAboutMe === 0">{{ aboutMe }}</td>
          <td v-else-if="editingAboutMe === 1">
            <v-textarea class="mt-3" height="100px" outlined full-width v-model="aboutMe"></v-textarea>
          </td>
          <td v-else><a @click.prevent="editingAboutMe = 1">Add something about yourself</a></td>
          <td>
            <img v-if="editingAboutMe === 0" @click="editingAboutMe = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateAboutMeBtnLoad" v-if="editingAboutMe === 1" height="30"
                   @click="changeAboutMe()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingAboutMe === 1" height="30" @click="editingAboutMe = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <tr>
          <th class="font-weight-bold" style="font-size: 14px">Display Name</th>
          <td v-if="displayName !== null && editingDisplayName === 0">{{ displayName }}</td>
          <td v-else-if="editingDisplayName === 1">
            <v-text-field class="mt-3" outlined full-width v-model="displayName"></v-text-field>
          </td>
          <td v-else><a @click.prevent="editingDisplayName = 1">Add display name</a></td>
          <td>
            <img v-if="editingDisplayName === 0" @click="editingDisplayName = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateDisplayNameBtnLoad" v-if="editingDisplayName === 1" height="30"
                   @click="changeDisplayName()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingDisplayName === 1" height="30" @click="editingDisplayName = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <tr>
          <th class="font-weight-bold" style="font-size: 14px">Slogan</th>
          <td v-if="slogan !== null && editingslogan === 0">{{ slogan }}</td>
          <td v-else-if="editingslogan === 1">
            <v-text-field class="mt-3" outlined full-width v-model="slogan"></v-text-field>
          </td>
          <td v-else><a @click.prevent="editingslogan = 1">Add slogan</a></td>
          <td>
            <img v-if="editingslogan === 0" @click="editingslogan = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateSloganButtonLoad" v-if="editingslogan === 1" height="30"
                   @click="changeslogan" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingslogan === 1" height="30" @click="editingslogan = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <tr>
          <th class="font-weight-bold" style="font-size: 14px">Title</th>
          <td v-if="title !== null && editingTitle === 0">{{ title }}</td>
          <td v-else-if="editingTitle === 1">
            <v-select
              class="mt-3"
              outlined
              v-model="title"
              :items="titles"
              item-text="name"
              item-value="name">
            </v-select>
          </td>
          <td v-else><a @click.prevent="editingTitle = 1">Add Title</a></td>
          <td>
            <img v-if="editingTitle === 0" @click="editingTitle = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateTitleButtonLoad" v-if="editingTitle === 1" height="30"
                   @click="changeTitle" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingTitle === 1" height="30" @click="editingTitle = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Gender</th>
          <td v-if="gender !== null && editingGender === 0">{{ gender }}
          </td>
          <td v-else-if="editingGender === 1">
            <v-select
              outlined
              class="mt-3"
              :items="genders"
              v-model="gender"
              item-text="name"
              item-value="name">
            </v-select>
          </td>
          <td v-else><a @click.prevent="editingGender = 1">Add gender</a></td>
          <td>
            <img v-if="editingGender === 0" @click="editingGender = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateGenderButtonLoad" v-if="editingGender === 1" height="30"
                   @click="changeGender" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingGender === 1" height="30" @click="editingGender = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Name</th>
          <td v-if="editingName === 0 && user.full_name !== null">{{ fullName }}</td>
          <td v-else-if="editingName === 1">
            <v-text-field class="mt-3" outlined full-width v-model="fullName"></v-text-field>
          </td>
          <td v-else><a @click.prevent="editingName = 1">Add name</a></td>
          <td>
            <img v-if="editingName === 0" @click="editingName = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateNameButtonLoad" v-if="editingName === 1" height="30"
                   @click="changeFullname" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingName === 1" height="30" @click="editingName = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Date of Birth</th>
          <td v-if="DOB !== null && editingdob === 0 && DOB !== today" >{{ DOB }}</td>
          <td v-else-if="editingdob === 1">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="10"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  full-width
                  v-model="DOB"
                  label="Date of birth"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  color="blue"
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="mt-3"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="DOB"
                :max="today"
                @input="startDateMenu = false"
                show-current
              ></v-date-picker>
            </v-menu>
          </td>
          <td v-else><a @click.prevent="editingdob = 1">Add date of birth</a></td>
          <td>
            <img v-if="editingdob === 0" @click="editingdob = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateDOBButtonLoad" v-if="editingdob === 1" height="30" @click="changeDob"
                   block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingdob === 1" height="30" @click="editingdob = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Marital Status
            <v-tooltip v-if="editingMaritalStatus === 0" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                        v-on="on"  small color="primary">mdi-help-circle
                </v-icon>
              </template>
              <span>Why do we ask about your marital status?</span><p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
            </v-tooltip>
          </th>
          <td v-if="maritalStatus !== null && editingMaritalStatus === 0">
            {{ maritalStatus }}
          </td>
          <td v-else-if="editingMaritalStatus === 1">
            <v-row>
              <v-col cols="9">
                <v-select
                  outlined
                  class="mt-3"
                  :items="marital_status"
                  item-text="name"
                  item-value="name"
                  v-model="maritalStatus">
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-icon class="mt-7" color="primary">mdi-help-circle</v-icon>
              </v-col>
            </v-row>
          </td>
          <td v-else><a @click.prevent="editingMaritalStatus = 1">Add marital status</a></td>
          <td>
            <img v-if="editingMaritalStatus === 0" @click="editingMaritalStatus = 1"
                 src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateMaritalButtonLoad" v-if="editingMaritalStatus === 1" height="30"
                   @click="changeMaritalStatus" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingMaritalStatus === 1" height="30" @click="editingMaritalStatus = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Religion
            <v-tooltip v-if="editingReligion === 0" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                        v-on="on"  small color="primary">mdi-help-circle
                </v-icon>
              </template>
              <span>Why do we ask about your religion?</span><p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
            </v-tooltip>
          </th>
          <td v-if="religion !== null && editingReligion === 0">
            {{ religion }}
          </td>
          <td v-else-if="editingReligion === 1">
            <v-row>
              <v-col cols="9">
                <v-select
                  outlined
                  class="mt-3"
                  :items="religions"
                  item-text="name"
                  item-value="name"
                  v-model="religion">
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-icon class="mt-7" color="primary">mdi-help-circle</v-icon>
              </v-col>
            </v-row>
          </td>
          <td v-else><a @click.prevent="editingReligion = 1">Add religion</a></td>
          <td>
            <img v-if="editingReligion === 0" @click="editingReligion = 1"
                 src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateReligionButtonLoad" v-if="editingReligion === 1" height="30"
                   @click="changeReligion()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingReligion === 1" height="30" @click="editingReligion = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <!-- <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Nationality</th>
          <td v-if="editingNationality === 0 && user.nationality !== null">
            {{ user.nationality }}
          </td>
          <td v-else-if="editingNationality === 1">
            <v-select
              :items="countries"
              v-model="nationality"
              item-text="name"
              item-value="code"
              class="mt-3"
              outlined
            ></v-select>
          </td>
          <td v-else><a @click.prevent="editingNationality = 1">Add nationality</a></td>
          <td>
            <img v-if="editingNationality === 0" @click="editingNationality = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateNationalityBtnLoad" v-if="editingNationality === 1" height="30"
                   @click="changeNationality()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingNationality === 1" height="30" @click="editingNationality = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr> -->

        <!-- home country -->
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Home Country</th>
            <td v-if="editingHomeCountry === 0">
              <div v-if="user.home_country">{{ user.home_country.name }}</div>
              <a v-else @click.prevent="editingHomeCountry = 1">Add Home Country</a>
            </td>
            <td v-else>
              <v-select
                :items="countries"
                v-model="home_country"
                item-text="name"
                item-value="code"
                return-object
                class="mt-3"
                outlined
              ></v-select>
            </td>
          <!-- <td v-else><a @click.prevent="editingHomeCountry = 1">Add Home Country</a></td> -->
          <td>
            <img v-if="editingHomeCountry === 0" @click="editingHomeCountry = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateHomeCountryLoading" v-if="editingHomeCountry === 1" height="30"
                   @click="changeHomeCountry()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingHomeCountry === 1" height="30" @click="editingHomeCountry = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>

        <!-- home city -->
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Home City</th>
          <td v-if="editingHomeCity === 0 && user.home_city">
            {{ user.home_city }}
          </td>
          <td v-else-if="editingHomeCity === 1">
            <v-select
              :items="homeCities"
              v-model="home_city"
              class="mt-3"
              outlined
            ></v-select>
          </td>
          <td v-else><a @click.prevent="editingHomeCity = 1">Add Home City</a></td>
          <td>
            <img v-if="editingHomeCity === 0" @click="editingHomeCity = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateHomeCityLoading" v-if="editingHomeCity === 1" height="30"
                   @click="changeHomeCity()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingHomeCity === 1" height="30" @click="editingHomeCity = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>

        <!-- current country -->
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Current Country</th>
          <td v-if="editingCurrentCountry === 0">
            <div v-if="user.current_country">{{ user.current_country.name }}</div>
            <a v-else @click.prevent="editingCurrentCountry = 1">Add Current Country</a>
          </td>
          <td v-else>
            <v-select
              :items="countries"
              v-model="current_country"
              item-text="name"
              item-value="code"
              return-object
              class="mt-3"
              outlined
            ></v-select>
          </td>
          <!-- <td v-else><a @click.prevent="editingCurrentCountry = 1">Add Current Country</a></td> -->
          <td>
            <img v-if="editingCurrentCountry === 0" @click="editingCurrentCountry = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateCurrentCountryLoading" v-if="editingCurrentCountry === 1" height="30"
                   @click="changeCurrentCountry()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingCurrentCountry === 1" height="30" @click="editingCurrentCountry = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>

        <!-- current city -->
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Current City</th>
          <td v-if="editingCurrentCity === 0 && user.current_city">
            {{ user.current_city }}
          </td>
          <td v-else-if="editingCurrentCity === 1">
            <v-select
              :items="currentCities"
              v-model="current_city"
              class="mt-3"
              outlined
            ></v-select>
          </td>
          <td v-else><a @click.prevent="editingCurrentCity = 1">Add Current City</a></td>
          <td>
            <img v-if="editingCurrentCity === 0" @click="editingCurrentCity = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateCurrentCityLoading" v-if="editingCurrentCity === 1" height="30"
              @click="changeCurrentCity()" block
              color="primary white--text">
              <span style="color: white">Submit</span>
            </v-btn>
            <v-btn v-if="editingCurrentCity === 1" height="30" @click="editingCurrentCity = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <!-- <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Current City</th>
          <td  class="font-weight-bold" style="font-size: 14px">Alexandria</td>
          <td>
            <img src="../../assets/profile-edit-1.png">
          </td>
        </tr> -->
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Passport ID</th>
          <td>
            <div v-if="editingPassport === 0 && passportNumber !== null && passportExpiry !== null">
              <p class="mt-3">
                <strong class="ml-15">Number: </strong>{{ passportNumber }}
                <strong class="ml-15">Expiry Date: </strong>{{ passportExpiry }}
                <br>
                <strong v-if="passportIssuingCountry" class="ml-15">Issue Country: {{ passportIssuingCountry.name }}</strong>
              </p>
            </div>
            <div v-else-if="editingPassport === 1">
              <v-text-field
                v-model="passportNumber"
                outlined
                class="mt-2"
                label="Passport Number"></v-text-field>
              <label>Expiry Date</label>
              <v-menu
                v-model="menuPassport"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    full-width
                    v-model="passportExpiry"
                    label="Expiry Date"
                    append-icon="mdi-calendar"
                    color="blue"
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="passportExpiry"
                  @input="startDateMenu = false"
                  show-current
                ></v-date-picker>
              </v-menu>
              <label>Issuing Country</label>
              <v-select
              :items="countries"
              v-model="passportIssuingCountry"
              item-text="name"
              item-value="code"
              return-object
              class="mt-2"
              outlined
              ></v-select>
            </div>
            <div v-else><a @click.prevent="editingPassport = 1">Add passport</a></div>
          </td>
          <td>
            <img v-if="editingPassport === 0" @click="editingPassport = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updatePassportBtnLoad" v-if="editingPassport === 1" height="30"
                   @click="updatePassport()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingPassport === 1" height="30" @click="editingPassport = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        <!-- <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Issuing Country</th>
          <td v-if="passportIssuingCountry !== null && editingIssuingCountry === 0">{{
              countries.find(x => x.id === passportIssuingCountry) ? countries.find(x => x.id === passportIssuingCountry).name : 'None'
            }}
          </td>
          <td v-else-if="editingIssuingCountry === 1">
            <v-select
              :items="countries"
              v-model="passportIssuingCountry"
              item-text="name"
              item-value="id"
              class="mt-2"
              outlined
            ></v-select>
          </td>
          <td v-else><a @click.prevent="editingIssuingCountry = 1">Add issuing country</a></td>
          <td>
            <img v-if="editingIssuingCountry === 0" @click="editingIssuingCountry = 1"
                 src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateIssuingCountryBtnLoad" v-if="editingIssuingCountry === 1" height="30"
                   @click="changeIssuingCountry()" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingIssuingCountry === 1" height="30" @click="editingIssuingCountry = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr> -->
        <tr>
          <th  class="font-weight-bold" style="font-size: 14px">Full Address</th>
          <td v-if="address !== null && editingFulladdress === 0">{{ address }}</td>
          <td v-else-if="editingFulladdress === 1">
            <v-text-field class="mt-3" outlined full-width v-model="address"></v-text-field>
          </td>
          <td v-else><a @click.prevent="editingFulladdress = 1">Add address</a></td>
          <td>
            <img v-if="editingFulladdress === 0" @click="editingFulladdress = 1" src="../../assets/profile-edit-1.png">
            <v-btn :loading="updateAddressButtonLoad" v-if="editingFulladdress === 1" height="30"
                   @click="changeFulladdress" block
                   color="primary white--text">
              <span style="color: white">Submit</span></v-btn>
            <v-btn v-if="editingFulladdress === 1" height="30" @click="editingFulladdress = 0" block
                   plain class="mt-1">
              <span>Cancel</span></v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
      <v-btn class="ml-3" color="primary" small @click="$router.push('delete')">Delete user</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import user from '@/web_services/user'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      menu: null,
      aboutPhotosModal: 0,
      photoModalAboutValid: true,
      avatarPhotoAbout: null,
      menuPassport: null,
      passportNumber: null,
      passportExpiry: null,
      aboutMe: null,
      baseAboutMe: null,
      editingAboutMe: 0,
      updateAboutMeBtnLoad: null,
      displayName: null,
      editingDisplayName: 0,
      baseDisplayName: null,
      updateDisplayNameBtnLoad: null,
      editingIssuingCountry: 0,
      updatePassportBtnLoad: null,
      basePassportNumber: null,
      passportIssuingCountry: {},
      basePassportIssuingCountry: {},
      basePassportExpiry: null,
      rules: { required: value => !!value || 'This field Required.' },
      religions: [
        {
          value: 1,
          name: 'Muslim'
        },
        {
          value: 1,
          name: 'Christian'
        },
        {
          value: 1,
          name: 'Jewish'
        }
      ],
      genders: [
        {
          value: 1,
          name: 'Male'
        },
        {
          value: 2,
          name: 'Female'
        },
        {
          value: 3,
          name: 'Not Defined'
        },
        {
          value: 4,
          name: 'Prefer not to say'
        }],
      marital_status: [
        {
          value: 1,
          name: 'Single'
        },
        {
          value: 2,
          name: 'In a relationship'
        },
        {
          value: 3,
          name: 'Engaged'
        },
        {
          value: 4,
          name: 'Married'
        },
        {
          value: 5,
          name: 'It is complicated'
        },
        {
          value: 6,
          name: 'Separated'
        },
        {
          value: 7,
          name: 'Divorced'
        },
        {
          value: 8,
          name: 'Widowed'
        }
      ],
      titles: [
        {
          value: 1,
          name: 'Mr'
        },
        {
          value: 2,
          name: 'Ms'
        },
        {
          value: 3,
          name: 'Mrs'
        },
        {
          value: 4,
          name: 'Miss'
        }
      ],
      editingslogan: 0,
      updateTitleButtonLoad: false,
      updateNameButtonLoad: false,
      updateSloganButtonLoad: false,
      updateGenderButtonLoad: false,
      updateDOBButtonLoad: false,
      updateReligionButtonLoad: false,
      updateMaritalButtonLoad: false,
      updateIssuingCountryBtnLoad: false,
      updateNationalityBtnLoad: false,
      updateAddressButtonLoad: false,
      updateAvatarButtonLoad: false,
      editingName: 0,
      editingTitle: 0,
      editingGender: 0,
      editingdob: 0,
      editingMaritalStatus: 0,
      editingNationality: 0,
      editingHomeCountry: 0,
      updateHomeCountryLoading: false,
      home_country: {},
      editingHomeCity: 0,
      home_city: '',
      homeCities: [],
      updateHomeCityLoading: false,
      editingCurrentCountry: 0,
      updateCurrentCountryLoading: false,
      current_country: {},
      editingCurrentCity: 0,
      updateCurrentCityLoading: false,
      editingReligion: 0,
      editingPassport: 0,
      editingcurrentcity: 0,
      editingFulladdress: 0,
      slogan: null,
      baseslogan: null,
      fullName: null,
      basefullName: null,
      DOB: null,
      today: null,
      religion: null,
      baseReligion: null,
      baseDOB: null,
      address: null,
      baseAddress: null,
      gender: null,
      baseGender: null,
      maritalStatus: null,
      baseMaritalStatus: null,
      nationality: null,
      baseNationality: null,
      currentcity: null,
      basecurrentcity: null,
      title: null,
      countries: [],
      currentCities: [],
      current_city: null,
      basetitle: null,
      croppa: {}
    }
  },
  watch: {
    // nationality (val) {
    //   user.cities(val).then(res => {
    //     this.cities = res.data.data
    //   }).catch(error => {
    //     this.$emit('error', error)
    //   })
    // }
  },
  methods: {
    changeAboutMe () {
      if (this.aboutMe !== this.baseAboutMe && this.aboutMe !== '') {
        this.updateAboutMeBtnLoad = true
        const data = { about_me: this.aboutMe }
        user.update(data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.$emit('success', 'About me Edited Successfully')
        }).catch(error => {
          this.$emit('error', error)
        }).finally(() => {
          this.editingAboutMe = 0
          this.updateAboutMeBtnLoad = false
        })
      } else {
        this.editingAboutMe = 0
      }
    },
    changeDisplayName () {
      if (this.displayName !== this.baseDisplayName && this.displayName !== '') {
        this.updateDisplayNameBtnLoad = true
        const data = { user_name: this.displayName }
        user.update(data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.$emit('success', 'Display name Edited Successfully')
        }).catch(error => {
          this.$emit('error', error)
        }).finally(() => {
          this.editingDisplayName = 0
          this.updateDisplayNameBtnLoad = false
        })
      } else {
        this.editingDisplayName = 0
      }
    },
    updatePassport () {
      const passport = {
        passport_number: '',
        passport_expiry_date: '',
        passport_issuing_country: ''
      }
      this.updatePassportBtnLoad = true
      if (this.basePassportNumber !== this.passportNumber) {
        passport.passport_number = this.passportNumber
      } else {
        passport.passport_number = this.basePassportNumber
      }
      if (this.basePassportExpiry !== this.passportExpiry) {
        passport.passport_expiry_date = this.passportExpiry
      } else {
        passport.passport_expiry_date = this.basePassportExpiry
      }
      if (this.basePassportIssuingCountry !== this.passportIssuingCountry) {
        passport.passport_issuing_country = this.passportIssuingCountry.code
      } else {
        passport.passport_issuing_country = this.basePassportIssuingCountry.code
      }
      user.update(passport).then(res => {
        this.$store.dispatch('setUserData', res.data.data)
        this.$emit('success', 'Passport updated successfully')
      }).catch(error => {
        this.$emit('error', error)
      }).finally(() => {
        this.editingPassport = 0
        this.updatePassportBtnLoad = false
      })
    },
    changeIssuingCountry () {
      if (this.basePassportIssuingCountry !== this.passportIssuingCountry && this.passportIssuingCountry !== '') {
        this.updateIssuingCountryBtnLoad = true
        const data = { passport_issuing_country: this.passportIssuingCountry }
        user.update(data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          if (this.user.passport === null) {
            this.$emit('error', 'Theres no passport please add one below then change issuing country')
            this.passportIssuingCountry = null
          } else {
            this.$emit('success', 'Issuing country updated successfully')
          }
        }).catch(error => {
          this.$emit('error', error)
        }).finally(() => {
          this.editingIssuingCountry = 0
          this.updateIssuingCountryBtnLoad = false
        })
      } else {
        this.editingIssuingCountry = 0
      }
    },
    changeAvatarPhoto () {
      // if (this.$refs.photoFormAbout.validate()) {
      // if (this.photoModalAboutValid) {
      this.croppa.generateBlob((blob) => {
        blob.lastModifiedDate = new Date()
        blob.name = 'user_profile_image_' + new Date().toDateString()
        this.avatarPhotoAbout = blob
        // this.updateProfile()

        this.updateAvatarButtonLoad = true
        const formData = new FormData()
        formData.append('avatar', this.avatarPhotoAbout, this.avatarPhotoAbout.name)
        user.update(formData).then(() => {
          user.profile().then(res => {
            this.$store.dispatch('setUserData', res.data.data)
            this.emitSuccess('Avatar updated successfully')
          }).catch(error => {
            this.emitError('error', error)
          })
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateAvatarButtonLoad = false
          this.aboutPhotosModal = false
        })
        this.avatar = 0
        return true
      })
      // }
      // return false
    },
    onInit () {
      this.croppa.addClipPlugin(function (ctx, x, y, w, h) {
        ctx.beginPath()
        ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
        ctx.closePath()
      })
    },
    changeReligion () {
      if (this.religion !== this.baseReligion && this.religion !== '') {
        this.updateReligionButtonLoad = true
        const Data = { religion: this.religion }
        user.update(Data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Religion updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateReligionButtonLoad = false
          this.editingReligion = 0
          this.baseReligion = this.user.slogan
        })
      } else {
        this.editingReligion = 0
      }
    },
    changeslogan () {
      if (this.slogan !== this.baseslogan && this.slogan !== '') {
        this.updateSloganButtonLoad = true
        const Data = { slogan: this.slogan }
        user.update(Data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Slogan updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateSloganButtonLoad = false
          this.editingslogan = 0
          this.baseslogan = this.user.slogan
        })
      } else {
        this.editingslogan = 0
      }
    },
    changeFullname () {
      if (this.fullName !== this.basefullName && this.fullName !== '') {
        this.updateNameButtonLoad = true
        const data = { full_name: this.fullName }
        user.update(data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Full name updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateNameButtonLoad = false
          this.editingName = 0
          this.basefullName = this.user.full_name
        })
      } else {
        this.editingName = 0
      }
    },
    changeDob () {
      if (this.DOB !== this.baseDOB && this.DOB !== '') {
        this.updateDOBButtonLoad = true
        const Data = { date_of_birth: this.DOB }
        user.update(Data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Date of birth updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateDOBButtonLoad = false
          this.editingdob = 0
          this.baseDOB = this.date_of_birth
        })
      } else {
        this.editingdob = 0
      }
    },
    changeFulladdress () {
      if (this.address !== this.baseAddress && this.address !== '') {
        this.updateAddressButtonLoad = true
        const Data = { current_address: this.address }
        user.update(Data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Address updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateAddressButtonLoad = false
          this.editingFulladdress = 0
          this.baseAddress = this.current_address
        })
      } else {
        this.editingFulladdress = 0
      }
    },
    changeNationality () {
      if (this.nationality !== this.baseNationality && this.nationality !== '') {
        this.updateNationalityBtnLoad = true
        const data = { nationality_id: this.nationality }
        user.update(data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Nationality updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateNationalityBtnLoad = false
          this.editingNationality = 0
          this.baseNationality = this.user.nationality.id
        })
      } else {
        this.editingNationality = 0
      }
    },
    changeHomeCountry () {
      this.updateHomeCountryLoading = true
      const data = { home_country: this.home_country.code }
      user.update(data).then(res => {
        this.$store.dispatch('setUserData', res.data.data)
        this.getCities(this.home_country.code, 'home')
        this.home_city = ''
        this.changeHomeCity()
        this.emitSuccess('Nationality updated successfully')
      }).catch(error => {
        this.emitError('error', error)
      }).finally(() => {
        this.editingHomeCountry = 0
        this.updateHomeCountryLoading = false
      })
    },
    changeHomeCity () {
      this.updateHomeCityLoading = true
      const data = { home_city: this.home_city }
      user.update(data).then(res => {
        this.$store.dispatch('setUserData', res.data.data)
        this.emitSuccess('Nationality updated successfully')
      }).catch(error => {
        this.emitError('error', error)
      }).finally(() => {
        this.editingHomeCity = 0
        this.updateHomeCityLoading = false
      })
    },
    changeCurrentCountry () {
      this.updateCurrentCountryLoading = true
      const data = { current_country: this.current_country.code }
      user.update(data).then(res => {
        this.$store.dispatch('setUserData', res.data.data)
        this.getCities(this.current_country.code, 'current')
        this.current_city = ''
        this.changeCurrentCity()
        this.emitSuccess('Nationality updated successfully')
      }).catch(error => {
        this.emitError('error', error)
      }).finally(() => {
        this.editingCurrentCountry = 0
        this.updateCurrentCountryLoading = false
      })
    },
    changeCurrentCity () {
      this.updateCurrentCityLoading = true
      const data = { current_city: this.current_city }
      user.update(data).then(res => {
        this.$store.dispatch('setUserData', res.data.data)
        this.emitSuccess('Nationality updated successfully')
      }).catch(error => {
        this.emitError('error', error)
      }).finally(() => {
        this.editingCurrentCity = 0
        this.updateCurrentCityLoading = false
      })
    },
    getCities (country, type) {
      user.cities(country).then(res => {
        if (type === 'home') this.homeCities = res.data
        else this.currentCities = res.data
      }).catch(error => {
        this.$emit('error', error)
      })
    },
    changeGender () {
      if (this.gender !== this.baseGender && this.gender !== '') {
        this.updateGenderButtonLoad = true
        const data = { gender: this.gender }
        user.update(data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Gender updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateGenderButtonLoad = false
          this.editingGender = 0
          this.baseGender = this.user.gender
        })
      } else {
        this.editingGender = 0
      }
    },
    changeMaritalStatus () {
      if (this.maritalStatus !== this.baseMaritalStatus && this.maritalStatus !== '') {
        this.updateMaritalButtonLoad = true
        console.log('here', this.maritalStatus)
        const data = { marital_status_id: this.marital_status.find(el => el.name === this.maritalStatus).id }
        user.update(data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Marital status updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.updateMaritalButtonLoad = false
          this.editingMaritalStatus = 0
          this.baseMaritalStatus = this.user.marital_status
        })
      } else {
        this.editingMaritalStatus = 0
      }
    },
    changeTitle () {
      if (this.title !== this.basetitle && this.title !== '') {
        this.updateTitleButtonLoad = true
        const data = { title: this.title }
        user.update(data).then(res => {
          this.$store.dispatch('setUserData', res.data.data)
          this.emitSuccess('Title updated successfully')
        }).catch(error => {
          this.emitError('error', error)
        }).finally(() => {
          this.editingTitle = 0
          this.updateTitleButtonLoad = false
          this.basetitle = this.user.title_id
        })
      } else {
        this.editingTitle = 0
      }
    },
    emitSuccess (text) {
      this.$emit('success', text)
    },
    emitError () {
      this.$emit('error')
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created () {
    console.log(this.user)
    user.countries().then(res => {
      this.countries = res.data
    }).catch(error => {
      this.$emit('error', error)
    })
    user.maritalStatus().then(res => {
      this.marital_status = res.data
      console.log(res.data)
    }).catch(error => {
      this.$emit('error', error)
    })
    this.today = new Date().toISOString().slice(0, 10)
    if (this.user) {
      this.slogan = this.user.slogan
      this.baseslogan = this.user.slogan
      this.fullName = this.user.full_name
      this.basefullName = this.user.full_name
      this.DOB = this.user.date_of_birth
      this.baseDOB = this.user.date_of_birth
      this.address = this.user.current_address
      this.baseAddress = this.user.current_address
      this.gender = this.user.gender ? this.user.gender : null
      this.baseGender = this.user.gender ? this.user.gender : null
      this.maritalStatus = this.user.marital_status ? this.user.marital_status : null
      this.baseMaritalStatus = this.user.marital_status ? this.user.marital_status : null
      this.title = this.user.title ? this.user.title : null
      this.basetitle = this.user.title ? this.user.title : null
      this.religion = this.user.religion
      this.baseReligion = this.user.religion
      this.nationality = this.user.nationality
      this.baseNationality = this.user.nationality
      this.home_country = this.user.home_country
      if (this.home_country.code) this.getCities(this.home_country.code, 'home')
      this.home_city = this.user.home_city
      this.current_country = this.user.current_country
      if (this.current_country.code) this.getCities(this.current_country.code, 'current')
      this.current_city = this.user.current_city
      this.passportNumber = this.user.passport ? this.user.passport.passport_number : null
      this.basePassportNumber = this.user.passport ? this.user.passport.passport_number : null
      this.passportExpiry = this.user.passport ? this.user.passport.passport_expiry_date : null
      this.basePassportExpiry = this.user.passport ? this.user.passport.passport_expiry_date : null
      this.passportIssuingCountry = this.user.passport ? this.user.passport.passport_issuing_country : null
      this.basePassportIssuingCountry = this.user.passport ? this.user.passport.passport_issuing_country : null
      this.displayName = this.user.nick_name
      this.baseDisplayName = this.user.nick_name
      this.aboutMe = this.user.about_me
      this.baseAboutMe = this.user.about_me
    }
  }
}
</script>

<style scoped>
.profile-card {
  width: 100%;
}

.table {
  width: 100%;
}

.camera-image {
  position: absolute;
  bottom: 0;
}

.camera-image-frame {
  position: absolute;
  bottom: 0;
}

.values-table {
  width: 100%;
}

.about-avatar {
  width: fit-content;
}
</style>
